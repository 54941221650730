import React from "react";

export interface NavButtonProps {
    text: string;
    onClick: () => void;
}

export const NavButton = (props: NavButtonProps) => {
    return (
        <button className="nav-button" onClick={props.onClick}>
            <h2>
                {props.text}
            </h2>
        </button>
    );
}
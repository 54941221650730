import React from "react";
import "./Home.css";

export const Home = () => {
  const comingSoon = () => {
    return (
      <div className="ComingSoon">
        <h2>Coming Soon</h2>
      </div>
    );
  }
  return (
    <div className="Home">
      {comingSoon()}
    </div>
  );
}
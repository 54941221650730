import React, { createContext, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { TopBar } from './components/TopBar/TopBar';
import './App.css';
import { Home } from './pages/Home/Home';

export const BreakpointContext = createContext<number>(600);

const router = createBrowserRouter([
  { path: '/', element: <Home/>}
]);

const App = () => {
  const [breakpoint, setBreakpoint] = useState(600);

  return (
      <div className="App">
        <BreakpointContext.Provider value={breakpoint}>
          <TopBar />
          <div className="MainContent">
            <RouterProvider router={router} />
          </div>
        </BreakpointContext.Provider>
      </div>
  );
}


export default App;

import React, { useEffect } from "react";
import "./TopBar.css";
import useViewport from "../../hooks/useViewport";
import { BreakpointContext } from "../../App";
import { NavButton } from "./NavButton";

export const TopBar = () => {
  const { width } = useViewport();
  const breakpoint = React.useContext(BreakpointContext);

  const isMobile = width < breakpoint;

  const logoArea = () => {
    return (
      <>
        <img className="top-bar_logo" src="BarrelFireLogoSmall.png" alt="BarrelFire Games Logo" />
        <h1>BarrelFire Games</h1>
      </>
    );
  }

  return (
    <div className="top-bar">
      {logoArea()}
      <div className="nav-button-container">
        <NavButton text="Home" onClick={() => {}} />
        <NavButton text="About" onClick={() => {}} />
      </div>
    </div>
  );
};